import { useState, useEffect } from "react";
import useWindowSize from "@/hooks/useWindowSize";

const breakpoints = {
  xl: 1512,
  lg: 1152,
  md: 543,
  sm: 320,
};

export function useBreakPoints() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [breakpoint, setBreakPoint] = useState("lg");
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (window.innerWidth > breakpoints.xl) {
        setBreakPoint("xl");
      } else if (window.innerWidth > breakpoints.lg) {
        setBreakPoint("lg");
      } else if (window.innerWidth > breakpoints.md) {
        setBreakPoint("md");
      } else if (window.innerWidth > breakpoints.sm) {
        setBreakPoint("sm");
      } else {
        setBreakPoint("sm");
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return breakpoint;
}

export default breakpoints;
