import Head from "next/head";

import React from "react";

export default function SocialHead({ title = "", description = "", children }) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://ambulante.org/" />
      <meta property="og:site_name" content="Ambulante" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:site" content="@Ambulante" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {children}
    </Head>
  );
}
