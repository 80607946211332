import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { VerticalCard } from "./Cards";

// Import Swiper styles
import "swiper/css";

export default function CardsGallery({
  slides = [],
  slidesPerView = 1.5,
  spaceBetween = 15,
}) {
  return (
    <Swiper spaceBetween={spaceBetween} slidesPerView={slidesPerView}>
      {slides.map((post, i) => (
        <SwiperSlide key={i}>
          <VerticalCard
            key={post?.id}
            metadata={{
              date: new Date(),
              type: "Noticia",
              length: "",
            }}
            description={post?.attributes?.contenido}
            title={post?.attributes?.titulo}
            image={
              post?.attributes.cover_image.data?.attributes?.formats?.medium
                ?.url
            }
            link={`/blog/${post?.attributes.slug}`}
          ></VerticalCard>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
