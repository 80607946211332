import axios from "axios";
import useSWR from "swr";
import qs from "qs";
import apiClient from "@/utils/apiClient";
import { useEffect, useState } from "react";
import { previousWednesday } from "date-fns";

export const useMovies = (filters) => {
  const query = qs.stringify(
    {
      populate: "*",
      locale: "es-MX",
      pagination: {
        page: 1,
        pageSize: 50,
      },
      ...(filters && { filters }),
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`/api/films?${query}`, fetcher, {
    revalidateOnFocus: false,
  });
  return { data, isLoading: !error && !data, error, mutate };
};

export const useGetThemes = () => {
  const query = qs.stringify(
    {
      populate: "*",
      locale: "es-MX",
      pagination: {
        page: 1,
        pageSize: 50,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`/api/themes?${query}`, fetcher, {
    revalidateOnFocus: false,
  });
  return { data, isLoading: !error && !data, error, mutate };
};

export const useGetCountries = () => {
  const query = qs.stringify(
    {
      populate: "*",
      locale: "es-MX",
      pagination: {
        page: 1,
        pageSize: 50,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`/api/themes?${query}`, fetcher, {
    revalidateOnFocus: false,
  });
  return { data, isLoading: !error && !data, error, mutate };
};

export const useGetLanguages = () => {
  const query = qs.stringify(
    {
      populate: "*",
      locale: "es-MX",
      pagination: {
        page: 1,
        pageSize: 50,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`/api/idiomas?${query}`, fetcher, {
    revalidateOnFocus: false,
  });
  return { data, isLoading: !error && !data, error, mutate };
};

export const useMoviesByTopicStrapi = (topic) => {
  const query = qs.stringify(
    {
      populate: {
        peliculas: {
          populate: ["*"],
        },
      },
      locale: "en",
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`/api/themes?${query}`, fetcher);
  return { data, isLoading: !error && !data, error, mutate };
};

export const useTopicNamesStrapi = (topicId) => {
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`/api/themes/${topicId}`, fetcher);

  return { data, isLoading: !error && !data, error, mutate };
};

export const useGetRelatedMoviesList = (list, minMovies, maxThemes) => {
  const [data, setData] = useState([]);
  const [ok, setOk] = useState(true);
  const [loading, setLoading] = useState(true);

  const queryStr = [
    "?populate[0]=peliculas&",
    "populate[1]=peliculas.film_cover_image&",
    "populate[2]=peliculas.credits&",
    "populate[3]=peliculas.Directores&",
    "populate[4]=peliculas.countries&",
    "populate[5]=peliculas.idiomas&",
    "locale=es-MX",
  ];

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const urlList = () =>
          list.map((theme) =>
            `api/themes/${theme.id}`.concat(queryStr.join(""))
          );
        const res = await axios.all(
          urlList().map((endpoint) => apiClient.get(endpoint))
        );
        const resList = () =>
          res.map(
            ({
              data: {
                data: { attributes, id: topicId },
              },
            }) => {
              return {
                topicId: topicId,
                films: attributes.peliculas.data,
                themeName: attributes.name,
              };
            }
          );
        const filteredList = () =>
          resList()
            .sort((a, b) => a.films.length < b.films.length)
            .filter(({ films }) => films.length >= minMovies)
            .slice(0, maxThemes);
        const response = filteredList();
        setData(response);
      } catch (error) {
        setOk(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [minMovies, maxThemes]);
  return { data, ok, loading };
};

export const useGetFilmsByInitiatives = (
  initiative,
  current = 1,
  pageSize = 24
) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      populate: "*",
      locale: "es-MX",
      publicationState: "preview",
      filters: {
        iniciativas: {
          slug: { $eq: initiative },
        },
      },
      pagination: {
        page: current,
        pageSize: pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(
    initiative !== "" ? `/api/films?${query}` : null,
    fetcher,
    { revalidateOnFocus: false }
  );
  return { data, isLoading: !error && !data, error, mutate };
};

export const useGetRelatedFilms = (themeList, current = 1, pageSize = 24) => {
  // Convert theme list in object format for qs search
  const getQueryParams = () => {
    let res = [];
    // if params are in an array, map to query structure
    if (Array.isArray(themeList)) {
      res = themeList.map((q) => {
        return { temas: { id: { $eq: q } } };
      });
      res = { $or: res };
    } else {
      // if there is only one string as the param query structure is a bit different.
      res = { temas: { id: { $eq: themeList } } };
    }
    return res;
  };

  const qs = require("qs");
  const query = qs.stringify(
    {
      populate: "*",
      locale: "es-MX",
      publicationState: "preview",
      filters: getQueryParams(),
      pagination: {
        page: current,
        pageSize: pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`/api/films?${query}`, fetcher, {
    revalidateOnFocus: false,
  });
  return { data, isLoading: !error && !data, error, mutate };
};

export const useGetFilterSearch = (paramList, current = 1, pageSize = 24) => {
  const themeList = paramList.themes ? paramList.themes : [];
  const countriesList = paramList.langs ? paramList.langs : [];
  // Convert theme list in object format for qs search
  const getQueryParams = () => {
    let res = [];
    // if params are in an array, map to query structure
    if (Array.isArray(themeList)) {
      res = themeList.map((q) => {
        return { temas: { id: { $eq: q } } };
      });
      res = { $or: res };
    } else {
      // if there is only one string as the param query structure is a bit different.
      res = { temas: { id: { $eq: themeList } } };
    }
    return res;
  };

  // Convert theme list in object format for qs search
  const getQueryParams2 = () => {
    let res = [];
    // if params are in an array, map to query structure
    if (Array.isArray(countriesList)) {
      res = countriesList.map((q) => {
        return { idiomas: { id: { $eq: q } } };
      });
      res = { $or: res };
    } else {
      // if there is only one string as the param query structure is a bit different.
      res = { idiomas: { id: { $eq: countriesList } } };
    }
    return res;
  };

  // Here I merge the queries so they follow the struct {$or: [{}, {}, ...]}
  const mergeFilters = {
    $or: getQueryParams()["$or"].concat(getQueryParams2()["$or"]),
  };

  const qs = require("qs");
  const query = qs.stringify(
    {
      populate: "*",
      locale: "es-MX",
      publicationState: "preview",
      filters: mergeFilters,
      pagination: {
        page: current,
        pageSize: pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`/api/films?${query}`, fetcher, {
    revalidateOnFocus: false,
  });
  return { data, isLoading: !error && !data, error, mutate };
};

export const useGetEvents = () => {
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR("/api/events", fetcher);
  return { data, isLoading: !error && !data, error, mutate };
};

export const useGetScreenings = () => {
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR("/api/screenings", fetcher);
  return { data, isLoading: !error && !data, error, mutate };
};

export const useBounceBack = (value, delay) => {
  const [bounce, setBounce] = useState(value);
  useEffect(() => {
    const bounceOut = setTimeout(() => {
      setBounce(value);
    }, delay);
    return () => clearTimeout(bounceOut);
  }, [value]);
  return bounce;
};

export const useSearchFilm = (film, current = 1, pageSize = 5, order) => {
  const query = qs.stringify(
    {
      populate: ["Directores", "countries", "film_cover_image", "filmSlug"],
      filters: {
        $or: [
          { Directores: { Director: { $containsi: film } } },
          { film_name: { $containsi: film } },
        ],
      },
      sort: [order],
      pagination: {
        pageSize: pageSize,
        page: current,
      }
    }
  );

  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(
    film !== "" ? `/api/films?${query}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return { data, isLoading: !error && !data, error, mutate };
};
